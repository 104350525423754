import { Box, Typography, styled } from '@mui/material'
import { useFormTemplate } from 'api/formTemplates/queries/useFormTemplate'
import { FormTemplateSection } from 'pages/consentAndRights/forms/templates/preview/components'
import { BuilderFormSectionHeading } from '../../../../../../BuilderFormSectionHeading'
import { BuilderFormField, BuilderFormFieldProps } from '../../../../../../BuilderFormField'
import { BuilderCheckbox, BuilderCheckboxProps, CheckboxVariant } from '../../../../../../BuilderCheckbox'
import { useContext } from 'react'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { Spinner, Icon, theme } from '@ketch-com/deck'
import { RightsFormMode } from '@ketch-sdk/ketch-types'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'

type FormSection = {
  title: string
  subtitle: string
  fields: BuilderFormFieldProps[]
  checkboxes?: BuilderCheckboxProps[]
}

const sampleFormData: FormSection[] = [
  {
    title: 'Personal Details',
    subtitle: 'Please provide your identifying information so we can locate you in our systems.',
    fields: [
      {
        label: 'Full Name',
        input: 'Kimberly Robbinson',
      },
      {
        label: (
          <Typography variant="label">
            Email{' '}
            <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
              (Active)
            </Typography>
          </Typography>
        ),
        isActive: true,
      },
      {
        label: 'Country',
        variant: 'dropdown',
        hasError: true,
      },
    ],
  },
  {
    title: 'Tell Us More',
    subtitle: 'Give us more context on what you’re trying to achieve with this request.',
    fields: [
      {
        label: 'Request Details',
        variant: 'paragraph',
      },
    ],
    checkboxes: [
      {
        variant: CheckboxVariant.on,
        label: 'I am not a customer',
      },
      {
        variant: CheckboxVariant.off,
        label: 'I am an active customer.',
      },
      {
        variant: CheckboxVariant.off,
        label: 'I am an employee',
        hasError: true,
      },
    ],
  },
]

const RequestsFormContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  marginTop: '36px',
})

const FormSectionBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
})

const FormFieldsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '28px',
})

const CheckboxesBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
})

export const RequestsFormContent: React.FC = () => {
  const { values } = useFormikContext<ExperienceV2DTO>()
  const { customFormID } = useContext(ExperienceUpsertContext)
  const { themeConfig } = useContext(BuilderContext)
  const { data: formTemplate, isLoading: isLoadingFormTemplate } = useFormTemplate({
    params: {
      formTemplateId: !!customFormID
        ? customFormID
        : getIn(values, experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsSingleFormID) || '',
      includeMetadata: true,
    },
  })

  const isSingleMode =
    getIn(values, experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsMode) === RightsFormMode.Single

  // Theme config
  const checkboxSelectedBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabFormCheckboxesSelectedBackgroundColor,
  )
  const checkboxSelectedLabelColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabFormCheckboxesSelectedLabelColor,
  )
  const checkboxUnselectedBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabFormCheckboxesUnselectedBackgroundColor,
  )
  const checkboxUnselectedLabelColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabFormCheckboxesUnselectedLabelColor,
  )
  const checkboxErrorBorderColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabFormCheckboxesErrorBackgroundColor,
  )
  const checkboxErrorTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabFormCheckboxesErrorLabelColor,
  )

  if (!isRelease(Release.FormsWithinExperienceServer)) {
    return (
      <RequestsFormContentBox>
        {sampleFormData.map(({ title, subtitle, fields, checkboxes }) => (
          <FormSectionBox>
            <BuilderFormSectionHeading title={title} subtitle={subtitle} />
            <FormFieldsBox>
              {fields.map(({ label, variant, isActive, hasError, input }) => (
                <BuilderFormField
                  label={label}
                  variant={variant}
                  isActive={isActive}
                  hasError={hasError}
                  input={input}
                />
              ))}
            </FormFieldsBox>
            {checkboxes && (
              <CheckboxesBox>
                {checkboxes?.map(({ variant, label, hasError }) => (
                  <BuilderCheckbox
                    variant={variant}
                    label={label}
                    onBackgroundColor={checkboxSelectedBackgroundColor}
                    onTextColor={checkboxSelectedLabelColor}
                    offBackgroundColor={checkboxUnselectedBackgroundColor}
                    offTextColor={checkboxUnselectedLabelColor}
                    hasError={hasError}
                    errorBorderColor={checkboxErrorBorderColor}
                    errorTextColor={checkboxErrorTextColor}
                    errorLabel="Required"
                  />
                ))}
              </CheckboxesBox>
            )}
          </FormSectionBox>
        ))}
      </RequestsFormContentBox>
    )
  }

  return (
    <Box display="flex" flexDirection="column" mt={4}>
      {!customFormID && !isSingleMode ? (
        <Box
          px={2}
          height={35}
          bgcolor={theme.palette.fadedGrey.main}
          borderRadius={35}
          display="flex"
          alignItems="center"
          gap={1}
          overflow="hidden"
        >
          <Icon name="FEyeShow" iconColor={theme.palette.darkDuskFaded.main} />
          <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
            Preview a form in the builder by selecting the view option from the "..." button.
          </Typography>
        </Box>
      ) : (
        <>
          {isLoadingFormTemplate || !formTemplate ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Spinner size="32px" thickness={2.5} />
            </Box>
          ) : (
            <Box sx={{ '& > div:last-of-type': { marginBottom: 0 } }}>
              {formTemplate?.sections?.map(section => (
                <FormTemplateSection formTemplateSection={section} key={JSON.stringify(section)} />
              ))}
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
