import { Modal } from 'components/ui-kit/modal/Modal'
import { Formik } from 'formik'
import { Box, styled } from '@mui/material'

import { RestoreBadge } from '../Badges'
import { useParams } from 'react-router-dom'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useUpdateAssignedCategories } from 'api/dataSystemsClassifications/mutations/useUpdateAssignedCategories'
import { AssignedClassificationV3DTO, ConfirmationStatusV3DTO } from '@ketch-com/figurehead'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useQueryClient } from 'react-query'
import { useAssignedDataCategoriesInfinite } from 'api/dataSystemsClassifications/queries/useAssignedDataCategoriesInfinite'

const ModalBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '2rem',
}))

const BadgesContainer = styled(Box)(({ theme: { spacing } }) => ({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  gap: spacing(2),
  padding: spacing(2, 0, 0, 0),
}))

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const AssignedCategoriesRestoreModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { code } = useParams<{ code: string }>()

  const { data: dataAssignedCategories } = useAssignedDataCategoriesInfinite({
    installedDataSystemId: code,
    limit: 9000,
  })

  const queryClient = useQueryClient()

  const { mutateAsync: handleUpdateAssignedCategories } = useUpdateAssignedCategories({
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.assignedDataCategories)

      showToast({ content: 'Assigned data categories updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update assigned data categories', type: 'error' })
    },
  })

  const onSubmit = (values: { assignedCategories: AssignedClassificationV3DTO[] }) => {
    handleUpdateAssignedCategories({
      params: {
        installedDataSystemId: code,
        formData: { assignedClassifications: values.assignedCategories },
      },
    })
  }

  const handleConfirmation = (assignedCategoryId: string | undefined, confirmationStatus: ConfirmationStatusV3DTO) => {
    const assignedCategoriesRemap = dataAssignedCategories?.assignedClassifications?.map(assignedCategory => {
      if (assignedCategory.id === assignedCategoryId) {
        return {
          ...assignedCategory,
          confirmationStatus,
        }
      } else {
        return assignedCategory
      }
    })

    handleUpdateAssignedCategories({
      params: {
        installedDataSystemId: code,
        formData: { assignedClassifications: assignedCategoriesRemap },
      },
    })
  }

  const assignedCategoriesDismissed = dataAssignedCategories?.assignedClassifications.filter(
    assignedCategory => assignedCategory.confirmationStatus === ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
  )

  const initialValues = {
    assignedCategories: assignedCategoriesDismissed ?? [],
  }

  return (
    <Formik enableReinitialize validateOnMount initialValues={initialValues} onSubmit={onSubmit}>
      {({ values }) => {
        return (
          <Modal
            title="Data Categories"
            subtitle={`Data Categories Dismissed ${assignedCategoriesDismissed.length ?? 0}`}
            isOpen={isOpen}
            onCloseButtonClick={() => onClose()}
            contentWidth={1000}
            submitBtnVariant="primary"
          >
            <ModalBody>
              <BadgesContainer>
                {values.assignedCategories.map(assignedCategory => {
                  return (
                    <Box key={assignedCategory.id}>
                      <RestoreBadge
                        name={assignedCategory?.classification?.name ?? ''}
                        handleRestore={() => {
                          handleConfirmation(assignedCategory?.id, ConfirmationStatusV3DTO.NoneConfirmationStatusV3)
                        }}
                      />
                    </Box>
                  )
                })}
              </BadgesContainer>
            </ModalBody>
          </Modal>
        )
      }}
    </Formik>
  )
}
