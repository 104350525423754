import { APIRequestParams } from 'api/common/utils'
import { WebTagDTO, WebTagPurposeFormDTO } from 'interfaces/webtags/WebTags'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
  webTagId: string
  formData: WebTagPurposeFormDTO
}>

type Response = WebTagDTO

export const updateWebTagPurposes = ({ organizationCode, webTagId, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/tags/${webTagId}/purposes`,
    }),
    formData,
  )
