import { useFormikContext } from 'formik'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { useCallback } from 'react'
import { PreferenceExperienceFormValues } from '../../../interfaces/PreferenceExperienceFormValues'
import { ConsentExperienceFormValues } from '../../consent/interfaces'
import { BannerField, fieldNames, ModalField, JitField, PreferenceField } from '../constants'
import { ExperienceFormField } from '../interfaces'

export const onRemoveHandledCases = [
  BannerField.MAIN_TITLE,
  BannerField.ACTION_BUTTON,
  ModalField.BODY_TITLE,
  ModalField.BODY_DESCRIPTION,
  JitField.BODY_DESCRIPTION,
  JitField.ACTION_BUTTON,
  PreferenceField.OVERVIEW_BODY_TITLE,
  PreferenceField.PREFERENCES_BODY_TITLE,
  PreferenceField.PREFERENCES_BODY_DESCRIPTION,
  PreferenceField.RIGHTS_BODY_TITLE,
]

export const useOnRemove = ({ organizationLanguages }: { organizationLanguages: LanguageWithStatusDTO[] }) => {
  const { setFieldValue } = useFormikContext<ConsentExperienceFormValues | PreferenceExperienceFormValues>()

  return {
    onRemove: useCallback(
      (field: ExperienceFormField) => {
        const languageCodes = organizationLanguages.map(({ language }) => language.code)

        const handleClearValues = (fields: string[], value: any) => {
          fields?.forEach?.(name => {
            setFieldValue(name, value)
          })
        }

        switch (field) {
          case BannerField.MAIN_TITLE: {
            handleClearValues(
              [
                fieldNames.consent.banner.title.root,
                ...languageCodes.map(code => fieldNames.consent.banner.title.translation(code)),
              ],
              '',
            )

            break
          }

          case BannerField.ACTION_BUTTON: {
            handleClearValues(
              [
                fieldNames.consent.banner.secondaryButtonText.root,
                ...languageCodes.map(code => fieldNames.consent.banner.secondaryButtonText.translation(code)),
              ],
              '',
            )

            break
          }

          case ModalField.BODY_TITLE: {
            handleClearValues(
              [
                fieldNames.consent.modal.bodyTitle.root,
                ...languageCodes.map(code => fieldNames.consent.modal.bodyTitle.translation(code)),
              ],
              '',
            )

            break
          }

          case ModalField.BODY_DESCRIPTION: {
            handleClearValues(
              [
                fieldNames.consent.modal.bodyDescription.root,
                ...languageCodes.map(code => fieldNames.consent.modal.bodyDescription.translation(code)),
              ],
              [],
            )

            break
          }

          case JitField.BODY_DESCRIPTION: {
            handleClearValues(
              [
                fieldNames.consent.jit.bodyDescription.root,
                ...languageCodes.map(code => fieldNames.consent.jit.bodyDescription.translation(code)),
              ],
              [],
            )

            break
          }

          case JitField.ACTION_BUTTON: {
            handleClearValues(
              [
                fieldNames.consent.jit.moreInfoText.root,
                ...languageCodes.map(code => fieldNames.consent.jit.moreInfoText.translation(code)),
              ],
              '',
            )

            break
          }

          case PreferenceField.OVERVIEW_BODY_TITLE: {
            handleClearValues(
              [
                fieldNames.preference.overview.bodyTitle.root,
                ...languageCodes.map(code => fieldNames.preference.overview.bodyTitle.translation(code)),
              ],
              '',
            )

            break
          }

          case PreferenceField.PREFERENCES_BODY_TITLE: {
            handleClearValues(
              [
                fieldNames.preference.consents.bodyTitle.root,
                ...languageCodes.map(code => fieldNames.preference.consents.bodyTitle.translation(code)),
              ],
              '',
            )

            break
          }

          case PreferenceField.PREFERENCES_BODY_DESCRIPTION: {
            handleClearValues(
              [
                fieldNames.preference.consents.bodyDescription.root,
                ...languageCodes.map(code => fieldNames.preference.consents.bodyDescription.translation(code)),
              ],
              [],
            )

            break
          }

          case PreferenceField.RIGHTS_BODY_TITLE: {
            handleClearValues(
              [
                fieldNames.preference.rights.bodyTitle.root,
                ...languageCodes.map(code => fieldNames.preference.rights.bodyTitle.translation(code)),
              ],
              '',
            )

            break
          }
        }
      },
      [organizationLanguages, setFieldValue],
    ),
  }
}
