import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { APIListRequestParams } from 'api/common/utils'
import { Ordering } from 'api/common/utils'
import { fetchFormTemplates } from '../fetchers/fetchFormTemplates'
import { FormTemplateSummaryDTO } from '@ketch-com/figurehead'

type Params = APIListRequestParams<{
  start?: number
  query?: string
  includeMetadata?: boolean
  includeDeleted?: boolean
  ordering?: Ordering<'created_at'> | Ordering<'title'>
}>

type Options = {
  enabled?: boolean
  itemsPerPage?: number
}

export const useFormTemplatesInfinite = ({ options, params }: { options?: Options; params: Params }) => {
  const { itemsPerPage: limit = 20 } = options || {}

  const resp = useInfiniteQuery({
    enabled: !!options?.enabled,
    queryKey: [
      ApiQueryKeys.formTemplatesPaginated,
      {
        includeMetadata: true,
        ...params,
      },
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchDatasetsResp = await fetchFormTemplates({
        limit,
        start: (pageParam - 1) * limit,
        ...params,
      })

      const { data } = fetchDatasetsResp

      data.formTemplates = data.formTemplates ?? []
      data.totalResults = data.totalResults ?? 0

      return data
    },
    getNextPageParam: ({ totalResults }, allPages) => {
      let totalAssetsFetchedSoFar = 0
      allPages?.forEach?.(p => {
        totalAssetsFetchedSoFar += p?.formTemplates?.length || 0
      })
      const nextPageParam = Math.ceil(totalAssetsFetchedSoFar / limit) + 1
      const getNextPageParamReturnValue = totalAssetsFetchedSoFar === totalResults ? undefined : nextPageParam
      return getNextPageParamReturnValue
    },
  })

  let infiniteFormTemplates: FormTemplateSummaryDTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    infiniteFormTemplates = [...infiniteFormTemplates, ...(page?.formTemplates || [])]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: infiniteFormTemplates,
    totalResults,
  }
}
