import { Chip, Icon } from '@ketch-com/deck'
import { TeamDTO } from '@ketch-com/figurehead'
import { AvatarEntity, UserAvatar } from 'components/avatar/UserAvatar'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useCallback } from 'react'

type TeamChipProps = {
  team?: TeamDTO
  showSecondaryLabel?: boolean
}

export const TeamChip: React.FC<TeamChipProps> = ({ team, showSecondaryLabel = true }) => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const isAdmin = isPermitted(PERMISSIONS.USER_ADMIN)

  const onClick = useCallback(() => {
    if (isAdmin) navigate(RoutesManager.settings.teams.view.root.getURL({ id: team?.id }))
  }, [team, isAdmin, navigate])

  if (!team) return null

  return (
    <Chip
      key={team.id}
      icon={
        <UserAvatar
          sx={{ ml: 1 }}
          alternativeText={team.label}
          user={{ id: team.id, name: team.label || team.name } as AvatarEntity}
        />
      }
      label={team.name || team.label}
      secondaryLabel={showSecondaryLabel ? 'Team' : undefined}
      onClick={onClick}
      onDelete={isAdmin ? onClick : undefined}
      deleteIcon={isAdmin ? <Icon name="OArrowCRight" /> : undefined}
      clickable={isAdmin}
    />
  )
}
