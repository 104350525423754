import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetStackResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  code: string
  includeMetadata?: boolean
}>

export const fetchStack = ({ code, includeMetadata }: Params) =>
  API.get<GetStackResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/stacks/${code}`,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
  )
