import React from 'react'
import { SxProps, Theme, Tooltip, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Checkbox, SwitchToggle } from '@ketch-com/deck'

interface IIdentityVerificationFormProps {
  checkboxOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  checkboxValue?: boolean
  containerSx?: SxProps<Theme>
  disabled?: boolean
  requisiteForm?: React.ReactNode
  switchOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  switchValue: boolean
  title: string
  tooltipContent?: React.ReactNode
  error?: React.ReactNode
}

export const ConfigSwitch: React.FC<IIdentityVerificationFormProps> = ({
  checkboxOnChange,
  checkboxValue,
  containerSx,
  disabled,
  requisiteForm,
  switchOnChange,
  switchValue,
  title,
  tooltipContent,
  error,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        borderBottom: !!switchValue ? 'none' : '1px solid',
        borderColor: 'iron.main',
        ...containerSx,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: ({ palette }) => (switchValue ? palette.superLightGrey.main : 'transparent'),
          borderRadius: ({ spacing }) => spacing(1.375),
          border: error ? '1px solid' : 'none',
          borderColor: error ? 'chileanFire.main' : undefined,
        }}
      >
        {/* Row 1 */}
        <Box py={2} px={2.25} display="flex" alignItems="center">
          {/* switch  */}

          <Box display="flex" alignItems="center" flex={1}>
            <Tooltip title={tooltipContent}>
              <SwitchToggle
                disabled={disabled}
                value={switchValue}
                checked={switchValue}
                onChange={switchOnChange}
                size="medium"
              />
            </Tooltip>

            {/* title */}

            <Typography
              variant="h4"
              color={switchValue ? 'greenHaze.main' : undefined}
              component="div"
              ml={4.5}
              sx={{
                opacity: disabled ? 0.5 : 1,
              }}
            >
              {title}
            </Typography>
          </Box>

          {/* checkbox */}

          <Box display={!!switchValue && !!checkboxOnChange ? 'flex' : 'none'} alignItems="center">
            <Checkbox checked={checkboxValue || false} onChange={checkboxOnChange} />

            <Typography ml={1} variant={checkboxValue ? 'label' : 'body'}>
              Required
            </Typography>
          </Box>
        </Box>

        {/* Row 2: Error Row */}
        {!!error && (
          <Box pl={2} mb={1} display="flex" alignItems="center">
            {error}
          </Box>
        )}

        {/* Row 3: Requisite Form */}

        <Box display={!!switchValue && requisiteForm ? 'flex' : 'none'} flexDirection="column">
          {requisiteForm}
        </Box>
      </Box>
    </Box>
  )
}
