import React from 'react'
import Box from '@mui/material/Box'
import { LanguageDTO, LanguageWithStatusDTO } from 'interfaces/languages/language'
import { TranslationDTO } from '../../upsert/interfaces'
import { Status, StatusState, StatusVariant } from '@ketch-com/deck'

type Props = {
  translationEntry: TranslationDTO
  enabledLanguages?: LanguageDTO[]
  organizationLanguages?: LanguageWithStatusDTO[]
}

export const LanguageCellRenderer: React.FC<Props> = ({
  translationEntry,
  organizationLanguages,
  enabledLanguages,
}) => {
  const englishName = enabledLanguages?.find(lang => lang.code === translationEntry?.code)?.englishName
  const isEnabled = organizationLanguages?.find(lang => lang?.language?.code === translationEntry?.code)?.enabled

  return (
    <Box display="flex" alignItems="center" gap={2.5}>
      <Status
        icon="Dot"
        label={englishName || ''}
        variant={StatusVariant.ghost}
        status={isEnabled ? StatusState.success : StatusState.draft}
      />
    </Box>
  )
}
